import React from 'react';

import {CrosslinkSectionCallout} from '@components/SectionCallout';
import Section from './StyledSection';
import {ThemeProvider, useTheme, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';

export default function CrosslinkSection({
  className,
  noBg = false,
  ...crossLinkBlock
}): JSX.Element | null {
  const theme = useTheme();
  if (!crossLinkBlock) return null;
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: noBg ? Colors.White : theme.bg,
        ctaBg: Colors.Transparent,
        ctaFg: Colors.Blue,
        ctaHoverBg: Colors.Blue,
        ctaHoverFg: Colors.White,
      }}
    >
      <Section aria-label={`Learn more about 2u`} className={className}>
        <ResponsiveContainer>
          <CrosslinkSectionCallout {...crossLinkBlock} />
        </ResponsiveContainer>
      </Section>
    </ThemeProvider>
  );
}
