import React from 'react';
import {StatChartBlockProps} from './StatChartBlockProps';

import {styled, theme, Fonts, Colors} from '@styles';
import {TwoColumn} from '@components/layout';

const ContentContainer = styled.div.withConfig({
  componentId: 'studenDataContainer'
})`
  max-width: 506px;
  width: 100%;
  grid-area: content;
`;

const Header = styled.div.withConfig({
  componentId: 'studentDataHeader'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.fg};
  font-size: ${30 / 16}em;
  line-height: ${37 / 30};
  letter-spacing: ${-0.82 / 30}em;
  margin-bottom: 1em;

  .wide & {
    font-size: ${40 / 16}em;
    line-height: ${49 / 40};
    letter-spacing: ${-1.09 / 40}em;
  }
`;

const LargeStat = styled.div.withConfig({
  componentId: 'studentDataLargeStat'
}).attrs(() => ({
  ['aria-hidden']: true,
}))`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${Colors.Robin};
  font-size: ${170 / 16}em;
  letter-spacing: ${-6.83 / 170}em;
  grid-area: stat;

  .wide & {
    justify-self: center;
    font-size: ${340 / 16}em;
    line-height: ${63 / 340};
    letter-spacing: ${-13.66 / 340}em;
  }
`;

const FinePrint = styled.div.withConfig({
  componentId: 'studentDataFinePrint'
})`
  ${Fonts.ProximaNova};
  font-size: ${12 / 16}em;
  line-height: ${17 / 12};
  color: ${theme.fg};
  max-width: 455px;
  width: 100%;
`;

const StudentDataContainer = styled(TwoColumn).withConfig({
  componentId: 'studentDataContainer'
})`
  align-items: center;
  margin: 0 0 4em;
  grid-template-areas:
    'stat'
    'content';

  .wide & {
    grid-template-areas: 'content stat';
    margin: 8em 0 10em;
  }
`;

export default function StudentData({chartImage, description, eyebrow, dataPoint}: StatChartBlockProps) {
  return (
    <StudentDataContainer>
      <ContentContainer>
        <Header>{eyebrow}</Header>
        <FinePrint>{description}</FinePrint>
      </ContentContainer>
      <LargeStat>{dataPoint}</LargeStat>
    </StudentDataContainer>
  );
}
