import React from 'react';
import Helmet from 'react-helmet';

const CanonicalUrl = ({canonicalUrl}: {canonicalUrl: string}): JSX.Element => {
  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalUrl;
