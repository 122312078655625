import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import HeroSectionStandalone from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import AmbientVideo from '@components/Video/AmbientVideo';
import {StatSection} from '@components/Stats';
import {
  LatestSection,
  StyledSection,
  CrosslinkSection,
  SubNavSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections';
import {
  HeroSectionCallout,
  BodySectionCallout,
  ChartWithTestimonyCallout,
} from '@components/SectionCallout';
import {CtaCardsGrid} from '@components/Card';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {usePageContext} from '@components/Page';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';
import HeroSectionBottomImage from '@components/Hero/HeroSectionBottomImage';
import JsonLd from '@components/JsonLd';

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'aboutLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const CrosslinkStyledSection = styled(CrosslinkSection).withConfig({
  componentId: 'aboutCrosslink'
})`
  background: ${Colors.White};
`;

const VideoContainer = styled(StyledSection).withConfig({
  componentId: 'aboutVideoContainer'
})`
  max-width: 80%;
  background: ${Colors.Transparent};
`;

export default function About({data}): JSX.Element {
  usePageContext({shouldShowShade: true});
  const {cms} = data;
  const {hero, body, searchDescription, seoTitle, canonicalUrl} = cms.page;
  const {header: heroHeader, image} = getDataFromBlockTypes(hero);
  const externalLinks = hero.filter(item => item.blockType == 'link_block');
  const {
    crossLink,
    intro,
    stats,
    ctaCards,
    testimonial,
    ctaCardsHeader,
    culture,
    leadership,
    video,
    aboutPlatform,
  } = getDataFromBlockTypes(body);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <JsonLd
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: '2U, Inc.',
          alternateName: '2U',
          url: 'http://2u.com',
          description: `${searchDescription}`,
          sameAs: [
            'https://www.facebook.com/2u',
            'https://twitter.com/2Uinc',
            'https://www.linkedin.com/company/2u',
            'https://en.wikipedia.org/wiki/2U_(company)',
          ],
        }}
      />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Midnight,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          {/* Hero image */}
          <ThemeProvider theme={{maxWidth: '600px', bg: Colors.Elm}}>
            <HeroSectionStandalone
              heroSectionType={'tall'}
              image={image.image}
            >
              <HeroSectionCallout heroProps={[heroHeader, externalLinks]} />
            </HeroSectionStandalone>
            <HeroSectionBottomImage image={image.image.childImageSharp} />
          </ThemeProvider>

          <SubNavSection filter={false} categories={detailPagesData} />

          {/* Intro Content */}
          <ThemeProvider
            theme={{
              minPadding: '2em',
              maxPadding: '6em 0 4em',
              maxWidth: '80%',
              fg: Colors.Charcoal,
              bg: Colors.White,
              ctaFg: Colors.Blue,
              ctaBg: Colors.Transparent,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <StyledSection>
              <BodySectionCallout {...intro} alignment={`center`} />
            </StyledSection>
          </ThemeProvider>
          {/* Animated video */}
          {video && video.video && (
            <VideoContainer>
              <AmbientVideo videoSrc={video.video.url} />
            </VideoContainer>
          )}
          {/* stats */}
          { aboutPlatform && (
            <ThemeProvider
              theme={{
                bg: Colors.Blue,
                fg: Colors.Charcoal,
                ctaFg: Colors.Blue,
                ctaBg: Colors.White,
                ctaHoverFg: Colors.White,
                ctaHoverBg: Colors.Blue,
              }}
            >
              <OneImageWithContentSection {...aboutPlatform} />
            </ThemeProvider>
          )}
          <ThemeProvider
            theme={{
              minPadding: '2em',
              maxPadding: '4em 0 0',
              fg: Colors.Charcoal,
              bg: Colors.White,
              maxWidth: '80%',
            }}
          >
            <StyledSection>
              <StatSection {...stats} />
            </StyledSection>
          </ThemeProvider>

          {/** Cta cards grid */}
          <ThemeProvider theme={{bg: Colors.White}}>
            <StyledSection noPadding>
              <ThemeProvider
                theme={{
                  bg: Colors.Blue,
                  fg: Colors.Charcoal,
                  ctaFg: Colors.White,
                  ctaIconFg: Colors.White,
                  ctaIconBg: Colors.White,
                  ctaHoverFg: Colors.Blue,
                  ctaHoverBg: Colors.White,
                }}
              >
                <CtaCardsGrid
                  ctaheaderBlock={ctaCardsHeader}
                  ctacardsBlock={ctaCards}
                />
              </ThemeProvider>
            </StyledSection>
          </ThemeProvider>
          {/* comment for rebuild */}
          <ChartWithTestimonyCallout {...testimonial} />
          <ThemeProvider
            theme={{
              bg: Colors.Blue,
              fg: Colors.Charcoal,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <OneImageWithContentSection {...culture} />
          </ThemeProvider>
          <TwoImagesWithContentSection {...leadership} contentLeft={true} />
          <LatestStyledSection />
          <CrosslinkStyledSection {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment AboutFragment on CMS_AboutPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...heroCmsImageFragment
      ...pagelinkBlockFragment
      ...externalLinkBlockFragment
    }
    body {
      ... on CMS_AboutSectionBodyType {
        __typename
        ...sectionBlockFragment
        ...testimonialBlockFragment
        ...crosslinkBlockFragment
        ...ctacardsFragment
        ...ctacardsHeaderFragment
        ...statsBlockFragment
        ...videoCmsFragment
        ...headerBlockFragment
        ...sectionBodyImageFragment
        ...sectionBodyTwoImagesFragment
      }
    }
  }
  query AboutPage {
    cms {
      page(url: "/home/about/") {
        ...AboutFragment
      }
    }
  }
`;
